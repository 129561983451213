.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Full viewport height */
    background-color: #3e3939;
    /* Optional: background color */
    animation: fadeInAnimation 1.2s ease-in-out;
}

.login-box {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
}

.login-box h2 {
    color: #F8F0E3;
    margin-bottom: 20px;
    cursor: default;
}

.login-box form div {
    margin-bottom: 15px;
}

.login-box label {
    color: #F8F0E3;
    display: block;
    margin-bottom: 5px;
    text-align: left;
}

.login-box input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

.login-box button {
    width: 100%;
    padding: 10px;
    background-color: #1b611a;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: "DeadlockFont", sans-serif;
}

.login-box button:hover {
    background-color: #145214;
}

.login-box p {
    color: #F8F0E3;
    margin-top: 15px;
    cursor: default;
}

/* Keyframes for fade-in animation */
@keyframes fadeInAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}