.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #3e3939;
    animation: fadeInAnimation 1.2s ease-in-out;
}

.signup-box {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    width: 300px;
}

.signup-box h2 {
    text-align: center;
    color: #F8F0E3;
    margin-bottom: 20px;
    cursor: default;
    font-family: "DeadlockFont", sans-serif;
}

.signup-box form div {
    margin-bottom: 15px;
}

.signup-box label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    color: #F8F0E3;
    font-family: "DeadlockFont", sans-serif;
}

.signup-box input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
}

.signup-box button {
    width: 100%;
    padding: 10px;
    background-color: #1b611a;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-family: "DeadlockFont", sans-serif;
}

.signup-box button:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Add shadow on hover */
    background-color: #145214;
    /* Ensure background color changes on hover */
    color: #fff;
    /* Ensure text color remains white on hover */
}

/* Keyframes for fade-in animation */
@keyframes fadeInAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}