@font-face {
  font-family: "DeadlockFont";
  src: url("../../assets/fonts/deadlockfont.ttf") format("truetype");
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;
  /* Increase padding for a bigger header */
  background-color: #333;
  color: #fff;
  font-size: 18px;
  /* Increase font size for the header */
  font-family: "DeadlockFont", sans-serif;
  /* Apply custom font */
  position: relative;
  z-index: 10;
}

.logo img {
  height: 80px;
  /* Increase the height of the logo */
}

.nav {
  flex-grow: 1;
}

.nav-list {
  list-style: none;
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 0;
  width: 100%;
}

.nav-item {
  color: #f8f0e3;
  /* Original color */
  margin-right: 20px;
  cursor: pointer;
  font-size: 28px;
  font-family: "DeadlockFont", sans-serif;
  padding: 5px;
  position: relative;
  text-decoration: none;
  /* Ensure no underline */
}

.nav-link {
  color: #f8f0e3;
  /* Ensure the color is the same as other nav items */
  text-decoration: none;
  /* No underline */
}

.nav-link:hover,
.nav-link:active,
.nav-link:focus,
.nav-link:visited {
  color: #f8f0e3;
  /* Ensure consistent color for the button */
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #444;
  list-style: none;
  padding: 10px 0;
  margin: 0;
  min-width: 150px;
  z-index: 15;
}

.dropdown-item {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 28px;
  font-family: "DeadlockFont", sans-serif;
  padding: 5px;
  position: relative;
}

.dropdown-item:hover {
  background-color: #555;
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}