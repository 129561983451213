.about-container {
  background-color: slategrey;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: "DeadlockFont", sans-serif;
  color: #333;
  padding: 20px;
  height: 100vh;
  box-sizing: border-box;
  flex-grow: 1;
}

.text-boxes {
  display: flex;
  justify-content: space-between;
  width: 84%;
  margin-top: 20px;
  flex-wrap: wrap;
}

/* Each text box */
.text-box {
  display: flex;
  flex-direction: column;
  /* Stack elements vertically */
  align-items: center;
  /* Center elements horizontally */
  justify-content: space-between;
  /* Add spacing between elements */
  flex: 1;
  margin: 0 10px;
  padding: 20px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 18px;
  text-align: center;
  width: 250px;
  opacity: 0;
  /* Initially hidden */
  animation: fadeInUp 1s ease-out forwards;
}

/* Staggering animation delay for each text box */
.text-box:nth-child(1) {
  animation-delay: 0.2s;
}

.text-box:nth-child(2) {
  animation-delay: 0.4s;
}

.text-box:nth-child(3) {
  animation-delay: 0.6s;
}

.text-box:nth-child(4) {
  animation-delay: 0.8s;
}

/* Name styles */
.text-box h3 {
  margin: 0;
  margin-bottom: 10px;
  /* Space between name and image */
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

/* Profile image */
.profile-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  max-width: 150px;
  margin-bottom: 10px;
  /* Space between image and LinkedIn icon */
}

/* LinkedIn icon */
.text-box img.logo {
  width: 25px;
  /* Adjust the size of the LinkedIn icon */
  height: auto;
  margin-top: 10px;
  /* Space between image and LinkedIn icon */
}

/* Keyframes for fading in from the bottom */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
    /* Start below the initial position */
  }

  to {
    opacity: 1;
    transform: translateY(0);
    /* End at the final position */
  }
}